Sofoot.modules.pubs = (() => {
    return {
        start() {
            this.lienSirData();
            this.insererAncreHabillage();
            this.setPair()
        },
        setPair() {
            if (Math.floor(Math.random() * 2) == 1) {
                document.body.dataset.isPair = true;
            } else {
                document.body.dataset.isPair = false;
            }
        },
        insererAncreHabillage() {
            if (!document.querySelector('#main')) return;
            if (document.querySelector('.crea-habillage')) return;
            let div = document.createElement('div');
            div.setAttribute('class', 'habillage');
            div.innerHTML = '<a target="_blank" rel="nofollow" class="crea-habillage"></a>';
            document.querySelector('#main').prepend(div);
        },
        activerHabillage(params) {
            return App.modules.ads.activerHabillage(params)
        },
        lienSirData() {
            const sirDataLiens = document.querySelectorAll('[href = "#sirdata-cmp"]');
            sirDataLiens?.forEach(lien => {
                lien.addEventListener('click', e => {
                    e.preventDefault();
                    window.Sddan.cmp.displayUI();
                    console.log("works !!");
                    return false;
                })
            })
        }
    }
})();